import React, { useState, useEffect, useRef } from 'react';
import { Typography, Box, TabScrollButton, Divider, Container, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import useStyles from './styles';
import clsx from 'clsx';
import { navigate } from "gatsby";

const Hero = ({pageContext, language}) => {

    const isInitialMount = useRef(true);

    useEffect(() => {   
      if (isInitialMount.current) {
        isInitialMount.current = false;
     } else {
      if(language.english){
        navigate("/social-enterprise-policies")
       }else{
        navigate("/polasaithe-fiontraiochta-soisialta")
       }
     }
    },[language.english]);

    const theme = useTheme();
    const classes = useStyles();
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));

    const color1 = pageContext?.heroBackgroundColour;
    const topColorPercent = matchesSm ? '100%' :'100%';
    const bottomColorPercent = matchesSm ? '0%' :'0%';

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const scrollButton = (props) => {
        return (
        props.direction === "left" ?
        <TabScrollButton orientation="horizontal" direction="left" sx={{color:'white'}} onClick={props.onClick}>
            <ArrowBackIcon  />
        </TabScrollButton> 
        :
        <TabScrollButton orientation="horizontal" direction="right" sx={{color:'white'}} onClick={props.onClick}>
            <ArrowForwardIcon  />
        </TabScrollButton> 
        );
      };

    return (
        <Grid container style={{background: `linear-gradient(to bottom,  ${color1} ${topColorPercent},#FFFFFF ${bottomColorPercent})`}}>
            <Container maxWidth="xl" role="banner" aria-label="hero banner">
                <Grid item container pt={matchesSm ? 2 : 8} pb={14} pl={matchesSm ? undefined : 4}>
                <Grid item xs={12} pt={matchesSm ? 6 : undefined}>
                    <Typography color="white" style={{fontSize: matchesSm ? '32px' : '56px', fontWeight:800, lineHeight:'64px'}} component="h1">{language.english ? 'Social Enterprise Policies' : 'Polasaithe Fiontraíochta Sóisialta'}</Typography>
                </Grid>
                    <Grid item xs={12}>                   
                      <Grid container pt={1}>
                        <Grid item xs={12} sm={1} pt={1.7}>
                        <Typography color={pageContext.heroFontColorBlack === true ? "black" : "white"}>
                            {language.english ? 'On this page:' : 'Ar an leathanach seo:'}
                        </Typography>
                        </Grid>
                          <Grid item sm={11} xs={12}>
                            {matchesMd ?
                            <Box sx={{ maxWidth: { xs: 480, sm: 480 }}}>
                                <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons
                                indicatorColor="primary"
                                textColor="primary"
                                ScrollButtonComponent={scrollButton}
                                aria-label="scrollable tabs"
                                >
                                <Tab sx={{textTransform: 'none'}} label={<AnchorLink onAnchorLinkClick={() => document.getElementById('contentone').focus()} className={classes.anchor} to={language.english ? `/social-enterprise-policies/#contentone` : `/social-enterprise-policies/#contentone`}><Typography color={pageContext.heroFontColorBlack === true ? "black" : "#FFE69D"} variant="primary2">{language.english ? 'Údarás na Gaeltachta Strategic Plan 2021-2025' : 'Plean Straitéiseach Údarás na Gaeltachta 2021-2025'}</Typography></AnchorLink>} />
                                <Tab sx={{textTransform: 'none'}} label={<AnchorLink onAnchorLinkClick={() => document.getElementById('contenttwo').focus()} className={classes.anchor} to={language.english ? `/social-enterprise-policies/#contenttwo` : `/social-enterprise-policies/#contenttwo`}><Typography color={pageContext.heroFontColorBlack === true ? "black" : "#FFE69D"} variant="primary2">{language.english ? 'National Social Enterprise Policy for Ireland 2019-2022' : 'Beartas Náisiúnta um Fhiontraíocht Shóisialta d’Éirinn 2019-2022'}</Typography></AnchorLink>} />
                                <Tab sx={{textTransform: 'none'}} label={<AnchorLink onAnchorLinkClick={() => document.getElementById('contentthree').focus()} className={classes.anchor} to={language.english ? `/social-enterprise-policies/#contentthree` : `/social-enterprise-policies/#contentthree`}><Typography color={pageContext.heroFontColorBlack === true ? "black" : "#FFE69D"} variant="primary2">{language.english ? 'European Policy Context' : 'Comhthéacs Beartais na hEorpa'}</Typography></AnchorLink>} />
                                </Tabs>
                            </Box> :
                            <Box display="flex" mt={1} >
                               
                                <Box display="flex">
                                <AnchorLink onAnchorLinkClick={() => document.getElementById('contentone').focus()} to={language.english ? `/social-enterprise-policies/#contentone` : `/social-enterprise-policies/#contentone`} className={classes.anchor}>
                                    <Box display="flex" ml={1} mr={1} p={1} className={clsx(classes.anchorlink, { [classes.anchorlinkBlack]: pageContext.heroFontColorBlack === true })}>
                                    <Typography variant="primary2" color={pageContext.heroFontColorBlack === true ? "black" : "#FFE69D"}>{language.english ? 'Údarás na Gaeltachta Strategic Plan 2021-2025' : 'Plean Straitéiseach Údarás na Gaeltachta 2021-2025'}</Typography>
                                    </Box>
                                </AnchorLink>
                                    <Divider orientation="vertical" flexItem sx={{background:pageContext.heroFontColorBlack ? "black" : "#FFE69D"}} />
                                </Box>
                                <Box display="flex">
                                <AnchorLink onAnchorLinkClick={() => document.getElementById('contenttwo').focus()} to={language.english ? `/social-enterprise-policies/#contenttwo` : `/social-enterprise-policies/#contenttwo`} className={classes.anchor}>
                                    <Box display="flex" ml={1} mr={1} p={1} className={clsx(classes.anchorlink, { [classes.anchorlinkBlack]: pageContext.heroFontColorBlack === true })}>
                                    <Typography variant="primary2" color={pageContext.heroFontColorBlack === true ? "black" : "#FFE69D"}>{language.english ? 'National Social Enterprise Policy for Ireland 2019-2022' : 'Beartas Náisiúnta um Fhiontraíocht Shóisialta d’Éirinn 2019-2022'}</Typography>
                                   
                                    </Box>
                                </AnchorLink>
                                    <Divider orientation="vertical" flexItem sx={{background:pageContext.heroFontColorBlack ? "black" : "#FFE69D"}} />
                                </Box>
                                <Box display="flex">
                                <AnchorLink onAnchorLinkClick={() => document.getElementById('contentthree').focus()} to={language.english ? `/social-enterprise-policies/#contentthree` : `/social-enterprise-policies/#contentthree`} className={classes.anchor}>
                                    <Box display="flex" ml={1} mr={1} p={1} className={clsx(classes.anchorlink, { [classes.anchorlinkBlack]: pageContext?.heroFontColorBlack === true })} style={{'&:hover': {border: "1px solid black!important",}}}>
                                   <Typography variant="primary2" color={pageContext.heroFontColorBlack === true ? "black" : "#FFE69D"}>{language.english ? 'European Policy Context' : 'Comhthéacs Beartais na hEorpa'}</Typography>
                                    </Box>
                                </AnchorLink>
                                </Box>
                            </Box>}
                          </Grid>
                          <Grid item  sm={12} md={6} lg={6} xl={6} mt={1.6}>
                              <Typography style={{fontSize: matchesSm ? "20px" : "24px", lineHeight:matchesSm ? '28px' : '32px', fontWeight: 400}} color="white" dangerouslySetInnerHTML={{__html:  language.english ? pageContext?.heroText : pageContext?.heroTextIrish}} />
                          </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
)}

export default Hero;