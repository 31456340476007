import React,{useState, useEffect} from "react";
import Layout from "../components/layout";
import LanguageContext from "../context/LanguageContext";
import Seo from "../components/seo";
import Hero from '../components/SocialPolicy/Hero/Hero';
import SocialPolicyMainContent from "../components/SocialPolicy/MainContent/MainContent";
import { Box } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

const SocialPolicyPage = ({ pageContext }) => {

  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
      setHasMounted(true);
  }, []);

  if(!hasMounted) return <div style={{ alignItems: "center", display: "flex", justifyContent: "center", height: "100vh", width: "100vw" }}><CircularProgress /></div>;

  return (
    <LanguageContext.Consumer>
      {language => (
        <Layout>
           <Seo title="Social Enterprise Policy" lang={language.english ? "en" : 'ga'} />
           <Hero language={language} pageContext={pageContext.acf_socialEnterprisePolicies} />
           <Box role="main">
            <SocialPolicyMainContent language={language} pageContext={pageContext.acf_socialEnterprisePolicies} />
            <SocialPolicyMainContent language={language} pageContext={pageContext.acf_socialEnterprisePolicies} backgroundBlue={true} content2={true} />
            <SocialPolicyMainContent language={language} pageContext={pageContext.acf_socialEnterprisePolicies} content3={true} />
           </Box>
        </Layout>
      )}
    </LanguageContext.Consumer>
    )}


export default SocialPolicyPage;

