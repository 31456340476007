import React from 'react';
import { Typography, Container, Grid, Avatar, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DownloadIcon from '@mui/icons-material/Download';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import useStyles from './styles';

const SocialPolicyMainContent = ({pageContext, language, backgroundBlue, content2, content3}) => {

    const classes = useStyles();
    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid container style={{background:backgroundBlue? '#E6E4EF' : null}}>
            <Container maxWidth="md">
                <Grid item xs={12} mt={10} mb={12} ml={matchesSm ? 4 : undefined} mr={matchesSm ? 4 : undefined}>
               
                    {content2 ?
                    <>
                     <Typography style={{fontWeight:800, fontSize:matchesSm ? '28px' : '40px', lineHeight:matchesSm ? '32px' : '48px'}} tabIndex={"0"} id="contenttwo" component="h2">{language.english ? pageContext.content2title : pageContext?.content2titleIrish}</Typography>
                     <br />
                    <Typography style={{fontSize:matchesSm ? '18px' : '21px', lineHeight:matchesSm ? '140%' : '30px'}} dangerouslySetInnerHTML={{__html:  language.english ? pageContext?.content2 : pageContext?.content2Irish}} />
                    <Grid item sm={12} md={6}>
                    <Box pt={2}>
                    <a style={{textDecoration:'none'}} target="_blank" rel="noopener noreferrer" aria-label={`${language.english ? pageContext?.content2filetext : pageContext?.content2filetextIrish}, opens in new tab`} href={pageContext?.content2file.mediaItemUrl}>
                            <Card className={classes.card}>
                                <Box pl={1} pr={1} pb={0.5}>
                                <CardContent>
                                <Box display="flex">
                                    <Avatar style={{backgroundColor:'#2C2C69'}}>
                                        <DownloadIcon />
                                    </Avatar>
                                    <Box ml={1}>
                                        <Typography gutterBottom variant="primary" component="h3">
                                        {language.english ? pageContext?.content2filetext : pageContext?.content2filetextIrish}
                                        </Typography>
                                    </Box>
                                </Box>
                                </CardContent>
                                </Box>
                            </Card>
                        </a>
                    </Box>
                    </Grid>
                    </>:
                    content3 ?   
                    <>
                    <Typography style={{fontWeight:800, fontSize:matchesSm ? '28px' : '40px', lineHeight:matchesSm ? '32px' : '48px'}} tabIndex={"0"} id="contentthree" component="h2">{language.english ? pageContext?.content3title : pageContext?.content3titleIrish}</Typography>
                    <br />
                    <Typography style={{fontSize:matchesSm ? '18px' : '21px', lineHeight:matchesSm ? '140%' : '30px'}} dangerouslySetInnerHTML={{__html:  language.english ? pageContext?.content3 : pageContext?.content3Irish}} /></>:
                    <>
                    <Typography style={{fontWeight:800, fontSize:matchesSm ? '28px' : '40px', lineHeight:matchesSm ? '32px' : '48px'}} tabIndex={"0"} id="contentone" component="h2">{language.english ? pageContext?.content1title : pageContext?.content1titleIrish}</Typography>
                    <br />
                    <Typography style={{fontSize:matchesSm ? '18px' : '21px', lineHeight:matchesSm ? '140%' : '30px'}} dangerouslySetInnerHTML={{__html:  language.english ? pageContext?.content1 : pageContext?.content1Irish}} />
                    <Grid item sm={12} md={6}>
                    <Box pt={2}>
                    <a style={{textDecoration:'none'}} id="main" target="_blank" aria-label={`${language.english ? pageContext?.content1filetext : pageContext?.content1filetextIrish}, opens in new tab`} rel="noopener noreferrer" href={pageContext?.content1file.mediaItemUrl}>
                        <Card className={classes.card}>
                            <Box pl={1} pr={1} pb={0.5}>
                            <CardContent>
                            <Box display="flex">
                                <Avatar style={{backgroundColor:'#2C2C69'}}>
                                    <DownloadIcon />
                                </Avatar>
                                <Box ml={1}>
                                    <Typography gutterBottom variant="primary" component="h3">
                                    {language.english ? pageContext?.content1filetext : pageContext?.content1filetextIrish}
                                    </Typography>
                                </Box>
                            </Box>
                            </CardContent>
                            </Box>
                        </Card>
                    </a>
                    </Box>
                    </Grid>
                    </>
                }
                </Grid>
            </Container>
        </Grid>
    )
}

export default SocialPolicyMainContent;