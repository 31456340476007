
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    btn:{
        borderRadius:20,
        paddingLeft:'30px',
        paddingRight:'30px',
        backgroundColor:'#FFE69D',
        "&:hover":{
          backgroundColor:'#FFCE3B'
        }
       },
    anchor:{
      textDecoration:'none',
    },
    anchorlink:{
      border:'1px solid transparent',
      "&:hover": {
       border:'1px solid #FFE69D'
      },
      borderRadius:'10px'
    },
    anchorlinkBlack:{
      border:'1px solid transparent',
      "&:hover": {
       border:'1px solid black'
      },
      borderRadius:'10px'
    },
    play:{
      backgroundColor:'#000000', 
      opacity:0.6, 
      height: '70px', 
      width: '70px',
      "&:hover": {
        opacity:0.8,
        cursor:'pointer'
      },
    }
});

export default useStyles;